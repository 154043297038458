















import { Component, Ref } from 'vue-property-decorator';
import { BaseComponent } from '@common-src/mixins/base-component';
import FormComponent from '@common-src/mixins/form-component';
import { FormControl, FormControlType, FormControlTextModel, FormControlSelectDeviceAttributeModel } from '@common-src/model/form-control';
import { ViewModeType } from '@common-src/model/enum';
import { EnergyService } from '@common-src/service/energy';
import { getDeviceIdAndAttributeKey, SelectDeviceAttributeModel } from '@common-src/entity-model/device-entity';
import DeviceService from '@common-src/service/device';

class FacilityConfigModel {
    constructor() {
        this.power = [new SelectDeviceAttributeModel()];
    }
    modelId:string=undefined;
    heatId:string=undefined;
    coldId:string = undefined;
    powerId:string = undefined;

    @FormControl({
        label: '热量',
        type: FormControlType.SELECT_DEVICE_ATTRIBUTE,
        multiple: false,
        required: true,
        layout: { labelCol: 4, wrapperCol: 18, width: '100%' }
    } as FormControlSelectDeviceAttributeModel)
    heat: string = undefined;

    @FormControl({
        label: '冷量',
        type: FormControlType.SELECT_DEVICE_ATTRIBUTE,
        multiple: false,
        required: true,
        layout: { labelCol: 4, wrapperCol: 18, width: '100%' }
    } as FormControlSelectDeviceAttributeModel)
    cold: string = undefined;

    @FormControl({
        label: '用电量',
        type: FormControlType.SELECT_DEVICE_ATTRIBUTE,
        multiple: false,
        mode: 'multiple',
        required: true,
        layout: { labelCol: 4, wrapperCol: 18, width: '100%' }
    } as FormControlSelectDeviceAttributeModel)
    power: Array<any> = undefined;

    toService() {
        let data = [];
        data.push({
            id: this.coldId,
            identifier: 'cold',
            name: '冷量',
            relations: [
                {
                    attrKey: getDeviceIdAndAttributeKey(this.cold).attributeKey,
                    devId: getDeviceIdAndAttributeKey(this.cold).deviceId
                }
            ]
        });
        data.push({
            id: this.heatId,
            identifier: 'heat',
            name: '热量',
            relations: [
                {
                    attrKey: getDeviceIdAndAttributeKey(this.heat).attributeKey,
                    devId: getDeviceIdAndAttributeKey(this.heat).deviceId
                }
            ]
        });
        data.push({
            id: this.powerId,
            identifier: 'power',
            name: '用电量',
            relations: this.power.map(item => {
                return {
                    attrKey: item.key,
                    devId: item.deviceId
                };
            })
        });
        let params = {
            id: this.modelId,
            name: 'DHC能效配置',
            type: 'DHC',
            attrs: data
        };
        return params;
    }
}

@Component
export default class FacilityConfigComponent extends BaseComponent {
    @Ref(JTL.CONSTANT.DEFAULT_FORM_NAME)
    jtlForm: FormComponent<FacilityConfigModel>;
    service: EnergyService = new EnergyService();
    dhcLoading: boolean = false;

    created() {
        this.dhcLoading = true;
        DeviceService.getAllDeviceAttributes().then(() => {
            this.service.getEnergyConfig().then(res => {
                const configModel = new FacilityConfigModel();
                configModel.modelId = res.id;

                // let heatRes = _.filter(res.attrs, item => { return item.identifier === 'heat'; });
                // if (heatRes && heatRes.length > 0 && heatRes[0] && heatRes[0].relations && heatRes[0].relations.length > 0) {
                //     configModel.heatId = heatRes[0].id;
                //     configModel.heat = heatRes[0].relations[0].devId + '|' + heatRes[0].relations[0].attrKey;
                // }

                // let coldRes = _.filter(res.attrs, item => { return item.identifier === 'cold'; });
                // if (coldRes && coldRes.length > 0 && coldRes[0] && coldRes[0].relations && coldRes[0].relations.length > 0) {
                //     configModel.coldId = coldRes[0].id;
                //     configModel.cold = coldRes[0].relations[0].devId + '|' + coldRes[0].relations[0].attrKey;
                // }

                // let powerRes = _.filter(res.attrs, item => { return item.identifier === 'power'; });
                // if (powerRes && powerRes.length > 0 && powerRes[0] && powerRes[0].relations && powerRes[0].relations.length > 0) {
                //     configModel.powerId = powerRes[0].id;
                //     configModel.power = powerRes[0].relations.map(item => {
                //         return new SelectDeviceAttributeModel().toModel(item);
                //     });
                // }

                _.forEach(_.get(res, 'attrs'), item => {
                    switch (item.identifier) {
                        case 'heat':
                            configModel.heatId = _.get(item, 'id');
                            configModel.heat = new SelectDeviceAttributeModel().toModel(_.get(item, 'relations[0]')).DeviceIdAndKey;
                            break;
                        case 'cold':
                            configModel.coldId = _.get(item, 'id');
                            configModel.cold = new SelectDeviceAttributeModel().toModel(_.get(item, 'relations[0]')).DeviceIdAndKey;
                            break;
                        case 'power':
                            configModel.powerId = _.get(item, 'id');
                            configModel.power = _.map(_.get(item, 'relations'), relation => {
                                return new SelectDeviceAttributeModel().toModel(relation).DeviceIdAndKey;
                            });
                            break;
                    }
                });

                this.jtlForm.initForm(configModel, ViewModeType.UPDATE);
            });
        }).finally(() => {
            this.dhcLoading = false;
        });
    }
    saveConfigClick() {
        return this.jtlForm.submitForm().then((res) => {
            let formData = this.jtlForm.formModel.toService();

            this.service.setEnergyConfig(formData).then(res => {
                this.showMessageSuccess('保存成功');
            });
        }).catch(err => {
            throw err;
        });
    }
}
